<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row>

        <v-col cols="3">
          <BackofficeNavigation class="sticky-block"/>
        </v-col>

        <v-col cols="9">


          <v-row align="center" justify="center">
            <v-col cols="4">
              <v-text-field rounded filled dense
                            v-model="keyword"
                            append-icon="mdi-magnify"
                            placeholder="Recherche"
                            single-line hide-details clearable/>
            </v-col>
            <v-col cols="8" class="text-right">
              <v-btn color="primary" outlined>
                <v-icon left>mdi-filter</v-icon>
                Filtrage
              </v-btn>
            </v-col>
          </v-row>


          <v-row v-if="isLoading">
            <v-col cols="6" v-for="el in 4" :key="el">
              <v-card class="rounded-lg shadow">
                <v-card-text>
                  <v-skeleton-loader type="article,actions"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


          <v-row v-if="!isLoading && investigations.length">

            <v-col cols="6" v-for="(item,i) in investigations" :key="i">
              <div class="translateY">
                <v-card class="rounded-lg shadow" :to="'/backoffice/completed/'+item.investigation_id">

                  <v-card-title>
                    {{ $func.setName(item.investigation.name, item.investigation.campaign.lang) }}
                    <v-spacer/>
                    <v-btn fab depressed small :to="'/backoffice/completed/'+item.investigation_id">
                      <v-icon>mdi-format-list-checks</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-card-subtitle>
                    CAMPAIGNE : {{ $func.setName(item.investigation.campaign.name, item.investigation.campaign.lang) }}

                    <v-spacer class="py-1"/>

                    <strong class="blue--text">
                      MONTANT :
                      {{ item.investigation.amount | toCurrency }}
                    </strong>

                    <v-spacer class="py-1"/>

                    <strong class="blue--text">
                      MONTANT TOTAL :
                      {{ (item.investigation.amount * item.total_validated)| toCurrency }}
                    </strong>

                  </v-card-subtitle>

                  <v-card-text>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="blue">mdi-clipboard-text-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.total }}</v-list-item-title>
                        <v-list-item-subtitle>Total</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="success">mdi-check-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Réponses validées</v-list-item-title>
                        <v-list-item-subtitle>Total : {{ item.total_validated }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="text-right">
                          <strong>{{ $func.percent(item.total_validated, item.total) }}%</strong>
                          <v-progress-linear
                              rounded
                              color="blue"
                              height="5"
                              :value="$func.percent(item.total_validated, item.total)"
                              class="w-100"
                              striped
                          ></v-progress-linear>
                        </div>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item two-line>
                      <v-list-item-avatar rounded class="bg-snipper">
                        <v-icon color="error">mdi-close-circle</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>Réponses réfusées</v-list-item-title>
                        <v-list-item-subtitle>Total : {{ item.total_refused }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="text-right">
                          <strong>{{ $func.percent(item.total_refused, item.total) }}%</strong>
                          <v-progress-linear
                              rounded
                              color="blue"
                              height="5"
                              :value="$func.percent(item.total_refused, item.total)"
                              class="w-100"
                              striped
                          ></v-progress-linear>
                        </div>
                      </v-list-item-action>
                    </v-list-item>

                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>


          <div class="d-flex justify-space-between my-5"
               v-if="total > $totalPerPage">
            <div class="w-100">
              <v-select dense filled
                        hide-details
                        :items="$perPage"
                        v-model="perPage"></v-select>
            </div>
            <v-spacer/>
            <v-pagination total-visible="10"
                          circle
                          v-model="pagination.current"
                          :length="pagination.total"
                          @input="getCompletedInvestigations"></v-pagination>
          </div>


          <v-card v-if="!isLoading && !investigations.length"
                  class="rounded-lg shadow">
            <v-card-text>
              <NoResult size="200"/>
            </v-card-text>
          </v-card>

        </v-col>

      </v-row>


    </v-container>
  </div>
</template>

<script>

import BackofficeNavigation from "@/views/backoffice/components/BackofficeNavigation";
import {HTTP} from "@/http-common";

export default {
  components: {BackofficeNavigation},
  data() {
    return {
      isLoading: false,
      investigations: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      keyword: null

    }
  },
  methods: {
    getCompletedInvestigations() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/backoffice/investigations/completed?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.investigations = res.data.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getCompletedInvestigations()
  },
  watch: {
    perPage() {
      this.getCompletedInvestigations()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/#', text: 'breadcrumb.backoffice', disabled: true},
        {to: '/#', text: 'backoffice.completed', disabled: true},
      ]
    },
  }
}
</script>

<style scoped>
</style>